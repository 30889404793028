const year = new Date().getFullYear();

export const languageTranslation = {
  es: {
    // Banner Header
    bannerHeaderAnchorPhoneTitle: "Teléfono",
    bannerHeaderAnchorEmailTitle: "Correo Electrónico",
    bannerHeaderButtonFlagTitle: "English Translation",
    bannerHeaderButtonWhatsappHref:
      "https://wa.me/51999064169?text=Hola, quisiera más información sobre los servicios que brinda DATAENTRYPERU.COM",
    bannerHeaderButtonEmailHref:
      "mailto:info@dataentryperu.com?subject=Correo enviado desde el formulario de contacto de DATAENTRYPERU.COM",

    // Navbar Header
    navbarHeaderAnchorInicioTitle: "Inicio",
    navbarHeaderAnchorNosotrosTitle: "Nosotros",
    navbarHeaderAnchorServiciosTitle: "Servicios",
    navbarHeaderAnchorContactoTitle: "Contacto",
    navbarHeaderAnchorInicioText: "Inicio",
    navbarHeaderAnchorNosotrosText: "Nosotros",
    navbarHeaderAnchorServiciosText: "Servicios",
    navbarHeaderAnchorContactoText: "Contacto",

    //  Diagram Main
    diagramMainItemDigitacion: "Digitación",
    diagramMainItemDigitalizacion: "Digitalización (escaneo)",
    diagramMainItemTranscripcion: "Transcripciones  Audio a Texto",
    diagramMainItemDesarrolloWeb: "Desarrollo Web",
    diagramMainItemDesarrolloSoftware: "Desarrollo de Software",
    diagramMainItemIngresoDatosWeb: "Ingreso de Datos en la Web",

    // Company Main
    companyMainTitle: "NOSOTROS",
    companyMainPrimaryText:
      "Somos una empresa peruana especializada en <strong>DIGITACIÓN</strong> masiva de datos (encuestas, fichas, cupones, formularios, etc.), <strong>DIGITALIZACIÓN</strong> (escaneo) de documentos, fotos o diapositivas, <strong>TRANSCRIPCIÓN</strong> de audio/video a texto, <strong>DESARROLLO WEB</strong>, <strong>DESARROLLO DE SOFTWARE</strong> e <strong>INGRESO DE DATOS EN LA WEB</strong>. Brindamos nuestros servicios a <strong>PERSONAS y EMPRESAS</strong> a nivel <strong>NACIONAL e INTERNACIONAL</strong>.",
    companyMainSecondaryText:
      "Te ofrecemos un servicio de calidad, con total puntualidad y confidencialidad.",

    // Phrase Main
    phraseMainText:
      "¡Que la demanda de datos no te distraiga de tu actividad principal!",

    // Services main
    servicesMainTitle: "SERVICIOS",

    servicesMainDigitacionImgAlt: "Servicio de Digitación",
    servicesMainDigitacionTitle: "DIGITACIÓN MASIVA DE DATOS",
    servicesMainDigitacionDetail:
      "Nos especializamos en el ingreso masivo de datos de <strong>encuestas, cupones, fichas, formularios, registros, etc.</strong> Toda la data ingresada es cuidadosamente revisada, validada y corregida para lograr un alto estándar de calidad.",

    servicesMainDigitalizacionImgAlt: "Servicio de Digitalización",
    servicesMainDigitalizacionTitle: "DIGITALIZACIONES (Escaneos)",
    servicesMainDigitalizacionDetail:
      "Transformamos documentos físicos en archivos digitales para un mejor acceso y conservación de tu información. Escaneamos y clasificamos todo tipo de <strong>documentos personales o empresariales, fotos y diapositivas.</strong>",

    servicesMainTranscriptionImgAlt: "Servicio de Transcripción",
    servicesMainTranscriptionTitle: "TRANSCRIPCIONES DE AUDIO A TEXTO",
    servicesMainTranscriptionDetail:
      "Transcribimos manualmente y con total confidencialidad, audios o videos a archivos de texto editables con marcación de tiempos. Transcribe <strong>reuniones, presentaciones, clases, focus group, conferencias, entrevistas, etc.</strong>",

    servicesMainDesarrolloWebImgAlt: "Servicio de Desarrollo Web",
    servicesMainDesarrolloWebTitle: "DESAROLLO WEB",
    servicesMainDesarrolloWebDetail:
      "Desarrollamos Páginas Web <strong>personales o empresariales</strong> 100% adaptables a dispositivos móviles. Implementamos y configuramos <strong>tiendas de comercio electrónico</strong>. Tenemos paquetes que incluyen el <strong>Hosting y Dominio.</strong>",

    servicesMainDesarrolloSoftwareImgAlt: "Servicio de Desarrollo de Software",
    servicesMainDesarrolloSoftwareTitle: "DESARROLLO DE SOFTWARE",
    servicesMainDesarrolloSoftwareDetail:
      "Desarrollamos <strong>sistemas y programas computacionales</strong>, orientado principalmente a <strong>personas particulares y a la micro/pequeña empresa</strong> que necesite simplificar, automatizar y optimizar sus procesos y tareas diarias.",

    servicesMainIngresoDatosWebImgAlt:
      "Servicio de Ingreso de Datos en Internet",
    servicesMainIngresoDatosWebTitle: "INGRESO DE DATOS EN INTERNET",
    servicesMainIngresoDatosWebDetail:
      "Administramos y actualizamos información de tus <strong>Redes Sociales, Blogs, Páginas Web, Tiendas Online,</strong> Gestores de Contenido (CMS - Content Management System), Sistemas de Gestión de Aprendizaje (LMS - Learning Management System), etc.",

    // Clients Main
    clientsMainTitle: "NUESTROS CLIENTES",

    // Contact Main
    contactMainTitle: "CONTÁCTANOS",
    contactMainName: "Nombre:",
    contactMainMail: "Correo:",
    contactMainMessage: "Mensaje:",
    contactMainSend: "Enviar Mensaje",
    contactMainAlertOkTitle: "¡Envío Exitoso!",
    contactMainAlertOkText:
      "Gracias por comunicarte con nosotros. Te responderemos lo antes posible.",
    contactMainAlertFailTitle: "¡Tu mensaje no pudo ser enviado!",
    contactMainAlertFailText: "Por favor, inténtalo de nuevo más tarde.",

    // Footer
    footerMainAddressIconTitle: "Dirección",
    footerMainAddressTextText: "Jesús María, Lima - PERÚ",
    footerMainAddressTextTitle: "Dirección",

    footerMainPhoneIconHref:
      "https://wa.me/51999064169?text=Hola, quisiera más información sobre los servicios que brinda DATAENTRYPERU.COM",
    footerMainPhoneIconTitle: "Móvil",
    footerMainPhoneTextHref:
      "https://wa.me/51999064169?text=Hola, quisiera más información sobre los servicios que brinda DATAENTRYPERU.COM",
    footerMainPhoneTextTitle: "Móvil",

    footerMainEmailIconHref:
      "mailto:info@dataentryperu.com?subject=Correo enviado desde DATAENTRYPERU.COM",
    footerMainEmailIconTitle: "Correo Electrónico",
    footerMainEmailTextHref:
      "mailto:info@dataentryperu.com?subject=Correo enviado desde DATAENTRYPERU.COM",
    footerMainEmailTextTitle: "Correo Electrónico",
    footerMainCopyrightText: `Copyright © ${year} DATA ENTRY PERÚ. Desarrollado por: Data Entry Perú |`,
  },

  en: {
    //Banner Header
    bannerHeaderAnchorPhoneTitle: "Cell Phone",
    bannerHeaderAnchorEmailTitle: "Email",
    bannerHeaderButtonFlagTitle: "Traducción al Español",
    bannerHeaderButtonWhatsappHref:
      "https://wa.me/51999064169?text=Hello, I would like more information about the services DATAENTYPERU.COM provides",
    bannerHeaderButtonEmailHref:
      "mailto:info@dataentryperu.com?subject=Email sent from the DATAENTRYPERU.COM contact form",

    // Navbar Header
    navbarHeaderAnchorInicioTitle: "Home",
    navbarHeaderAnchorNosotrosTitle: "About Us",
    navbarHeaderAnchorServiciosTitle: "Services",
    navbarHeaderAnchorContactoTitle: "Contact Us",
    navbarHeaderAnchorInicioText: "Home",
    navbarHeaderAnchorNosotrosText: "About Us",
    navbarHeaderAnchorServiciosText: "Services",
    navbarHeaderAnchorContactoText: "Contact Us",

    //  Diagram Main
    diagramMainItemDigitacion: "Data Entry",
    diagramMainItemDigitalizacion: "Document Scanning",
    diagramMainItemTranscripcion: "Audio to Text Transcription",
    diagramMainItemDesarrolloWeb: "Website Development",
    diagramMainItemDesarrolloSoftware: "Software Development",
    diagramMainItemIngresoDatosWeb: "Web Data Entry",

    // Company Main
    companyMainTitle: "ABOUT US",
    companyMainPrimaryText:
      "We are a Peruvian company specialized in massive <strong>DATA ENTRY</strong> (surveys, sheets, coupons, forms, etc.), <strong>DIGITIZATION</strong> (scanning) of documents, photos or slides, <strong>TRANSCRIPTION</strong> of audio/video to text, <strong>WEBSITE DEVELOPMENT</strong>, <strong>SOFTWARE DEVELOPMENT</strong> and <strong>WEB DATA ENTRY</strong>. We provide our services to <strong>NATIONAL or INTERNATIONAL</strong> people or companies.",
    companyMainSecondaryText:
      "We provide you a quality service, with punctuality and confidentiality.",

    // Phrase margin
    phraseMainText:
      "Don't let the demand for data distract you from your main activity!",

    // Services margin
    servicesMainTitle: "SERVICES",
    servicesMainDigitacionImgAlt: "Data Entry Service",
    servicesMainDigitacionTitle: "MASSIVE DATA ENTRY",
    servicesMainDigitacionDetail:
      "We specialize in massive data entry from <strong>surveys, coupons, tokens, forms, records, etc.</strong> All data entered is carefully reviewed, validated and corrected to achieve a high degree of quality.",

    servicesMainDigitalizacionImgAlt: "Document Scanning Service",
    servicesMainDigitalizacionTitle: "DOCUMENT SCANNING",
    servicesMainDigitalizacionDetail:
      "We transform physical documents into digital files for better access and conservation of your information. We scan and classify all types of <strong>personal or business documents, photos and slides.</strong>",

    servicesMainTranscriptionImgAlt: "Audio/Video Transcription Service",
    servicesMainTranscriptionTitle: "AUDIO/VIDEO TRANSCRIPTION",
    servicesMainTranscriptionDetail:
      "We manually transcribe audio/video into editable text files with timestamps. We transcribe <strong>meetings, presentations, classes, focus groups, conferences, interviews, etc.</strong> with complete confidentiality.",

    servicesMainDesarrolloWebImgAlt: "Web Development Service",
    servicesMainDesarrolloWebTitle: "WEB DEVELOPMENT",
    servicesMainDesarrolloWebDetail:
      "We develop <strong>personal or business websites</strong> that are 100% adaptable to mobile devices. We implement and configure e-commerce stores. We have packages that include <strong>Hosting and Domain.</strong>",

    servicesMainDesarrolloSoftwareImgAlt: "Software Development Service",
    servicesMainDesarrolloSoftwareTitle: "SOFTWARE DEVELOPMENT",
    servicesMainDesarrolloSoftwareDetail:
      "We develop <strong>computer systems and programs</strong>, aimed mainly at <strong>individuals and micro/small businesses</strong> that need to simplify, automate and optimize their processes and daily tasks.",

    servicesMainIngresoDatosWebImgAlt: "Web Data Entry Service",
    servicesMainIngresoDatosWebTitle: "WEB DATA ENTRY",
    servicesMainIngresoDatosWebDetail:
      "We manage and update information from your <strong>Social Networks, Blogs, Web Pages, Online Stores</strong>, Content Managers (CMS - Content Management System), Learning Management Systems (LMS - Learning Management System), etc.",

    // Clients Main
    clientsMainTitle: "OUR CLIENTS",

    // Contact Main
    contactMainTitle: "CONTACT US",
    contactMainName: "Enter your name:",
    contactMainMail: "Enter your email:",
    contactMainMessage: "Enter your message:",
    contactMainSend: "Send Message",
    contactMainAlertOkTitle: "Success!",
    contactMainAlertOkText: "Message sent successfully!",
    contactMainAlertFailTitle: "Your message could not be sent!",
    contactMainAlertFailText: "Please try again later.",

    // Footer
    footerMainAddressIconTitle: "Address",
    footerMainAddressTextText: "Jesus Maria, Lima - PERU",
    footerMainAddressTextTitle: "Address",

    footerMainPhoneIconHref:
      "https://wa.me/51999064169?text=Hello, I would like more information about the services provided by DATAENTRYPERU.COM",
    footerMainPhoneIconTitle: "Cell Phone",
    footerMainPhoneTextHref:
      "https://wa.me/51999064169?text=Hello, I would like more information about the services provided by DATAENTRYPERU.COM",
    footerMainPhoneTextTitle: "Cell Phone",

    footerMainEmailIconHref:
      "mailto:info@dataentryperu.com?subject=Email sent from DATAENTRYPERU.COM",
    footerMainEmailIconTitle: "Email",
    footerMainEmailTextHref:
      "mailto:info@dataentryperu.com?subject=Email sent from DATAENTRYPERU.COM",
    footerMainEmailTextTitle: "Email",

    footerMainCopyrightText: `Copyright © ${year} DATA ENTRY PERU. Developed by: Data Entry Peru |`,
  },
};
